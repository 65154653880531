import React, { useState } from 'react';
import imageTarget from '../../images/ref.png'
import imageTargetEN from '../../images/ref_en.png'
import Layoutas from '../components/Layout';
import './index.css';
import bg from '/images/bg.png'
import useTranslation from '../hook/useTranslation'
export default function RefAll() {
  const [user_roles, setUserRoles] = useState(null)
  const [T, setLang, lang] = useTranslation()

  return (
    <Layoutas
      backgroundColor='#fff' 
      getUserRoles={(roles) => {
        setUserRoles((Array.isArray(roles) && roles.length > 0) ? roles[0] : null)
      }}
      getLang={(lang, lang2) => {
        setLang(lang2)
      }}
    >
      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + bg + ')',
        backgroundSize: 'contain'
      }}>
        <div style={{
          width: '1200px',
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          paddingTop: 120,
          marginBottom: 200
        }}>
          <div className="font-bold text-3xl mb-2 text-center text-sky-800">{T("DATA_SOURCE")}</div>
          <div className='flex w-full  justify-center .'>
            <img className='w-full' src={lang === "EN" ? imageTargetEN : imageTarget} alt="image-target" />
          </div>

        </div>
      </div >
    </Layoutas >
  );
}
